import React from "react"
import './index.css'
import './app/App.css'
import needsSelector from "../assets/images/needs-selector.png"
import needEvaluator from "../assets/images/need-evaluator.png"
import infoDisplay from "../assets/images/info-display.png"
import shareDecision from "../assets/images/share-decision.png"
import { NextButton } from "../components/Icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'

const Page = () => {

    const titles = ["clarity",
                    "honesty",
                    "analysis",
                    "reflection",
                    "decision",
                    "celebration",
                    "more info"]

    const imgStyle = {
        height: 'auto',
        width: 'auto',
        maxWidth: '75%',
        maxHeight: '30vh'
    }

    const texts = [
    (<>
        <div>We begin by creating clarity about what is to be decided</div>
        <div><br/></div>
        <div>We ask you to articulate in words what you want to <nobr>decide . . .</nobr></div>
        <div><br/></div>
        <div><nobr>. . . and</nobr> two possible options to choose between</div>
    </>),
    (<>
        <div>We then ask you to think about the underlying needs you are looking to meet</div>
        <img alt="Need Selection Page from the App" style= {imgStyle} src={needsSelector}/>
        <div>This encourages you to reflect on what really matters to you in this decision</div>
    </>),
    (<>
        <div>After reflecting on what <nobr>matters . . .</nobr></div>
        <div><nobr>. . . we</nobr> ask you to consider the decision from each of the perspectives that you selected</div>
        <img alt="Need Evaluation Page from the App" style= {imgStyle} src={needEvaluator}/>
    </>),
    (<>
        <div>You may now feel ready to take a decision</div>
        <div><br/></div>
        <img alt="Option Evaluation Page from the App" style= {imgStyle} src={infoDisplay}/>
        <div><br/></div>
        <div>Or maybe it feels like something is still missing?</div>
    </>),
    (<>
        <div><br/></div>
        <div>You can revisit any aspect of the process as many times as you like</div>
        <div><br/></div>
        <div>Until you are ready to make up your <nobr>mind . . .</nobr></div>
    </>),
    (<>
        <div>Once you have made your decision, it's time to</div>
        <div>celebrate!</div>
        <img alt="Sharing Page from the App" style= {imgStyle} src={shareDecision}/>
        <div>Share your decision with others, with as much (or as little) detail as you like</div>
    </>),
    (<div>
        <br/>
        <NextButton
            root = {true}
            target = "/privacy"
            text = "privacy"/>
        <br/>
        <NextButton
            root = {true}
            target = "/support"
            text = "support us"/>
        <br/>
        <NextButton
            root = {true}
            target = "/about"
            text = "about"/>
        <br/>
        <NextButton
            root = {true}
            target = "/nvc"
            text = "resources"/>
        <br/>
        <NextButton
            root = {true}
            target = "https://blog.decision.ninja"
            text = "blog"/>
        <br/>
    </div>),
    ]

  return(
        <>
        <div className="base-layout" style={{maxWidth: '100vw'}}>
        <NextButton
                target = "/feedback"
                width = "100%"
                text = "NOTE: decision.ninja is in currently in early alpha - expect bugs and limitations"/>
        <Carousel showThumbs={false} showStatus={false}>
            {
                titles.map((title, index) => (
                        <CarouselPage
                            key={titles[index]}
                            title={titles[index]} 
                            text={texts[index]} 
                        />
                    )
                )
            }
        </Carousel>
        <NextButton
            target = "/decision"
            text = "start now"/>
        </div>
        </>
        
    )
}

const CarouselPage = (props) => {

    return (
        <div style= {{margin: 'auto', width: '100vw'}}>
            <div className="title">
                {props.title}
            </div>
            <div className="medium-text">
                {props.text}
                <br/>
            </div>
        </div>
    )
}

export default Page
